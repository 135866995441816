import { ViewStateType } from 'components/ProductTemplates/types';
import { Dispatch, SetStateAction } from 'react';
import { MessageType } from 'views/shared/FeedbackTemplate';
import { IPurchaseParams } from 'views/shared/services/executeBuy';
import { ActiveMarketOrder } from '__generated__/graphql';
import { ActiveOfferPriceListFormatedType } from 'types/serviceTypes';
import { IWineInfoTemplate } from '../components/SlideOutView/WineInfoTemplate';

export enum TRADE_HISTORY_REFS {
  DATE = 'date',
  TIME = 'time',
  WINENAME = 'wineName',
  REGION = 'region',
  PRODUCER = 'producer',
  VINTAGE = 'vintage',
  UNITSIZE = 'unitSize',
  LWIN18 = 'lwin18',
  QUANTITY = 'quantity',
  TRANSACTIONTYPE = 'transactionType',
  PRICE = 'price',
  TOTAL = 'total',
  BUYER = 'buyer',
  SELLER = 'seller',
}

export enum MARKET_REFS {
  SIZE = 'size',
  PRICE_RANGE = 'priceRange',
  PRICE = 'price',
  REGION = 'region',
  ID = 'id',
  ASSET_ACCOUNT_ID = 'assetAccountId',
  ACCOUNT_TYPE = 'accountType',
  LWIN18 = 'lwin18',
  LWIN11 = 'lwin11',
  VINTAGE = 'vintage',
  QUANTITY = 'quantity',
  WINENAME = 'wineName',
  UNITSIZE = 'unitSize',
  HIGHEST_BID = 'highestBid',
  MARKET_VALUE = 'marketValue',
  LOWEST_OFFER = 'lowestOffer',
  TOTAL_MARKET_VALUE = 'totalMarketValue',
  WAC = 'wac',
  ACTIONS = 'actions',
  LAST_TRADE_PRICE = 'lastTradePrice',
  OFFER = 'offer',
  QUANTITYOUTSTANDING = 'quantityOutstanding',
  PRODUCER = 'producer',
  VINOUS_SCORE = 'vinousScore',
  WA_SCORE = 'WAscore',

  HIGHEST_BID_PRICE = 'highestBidPrice',
  LOWEST_OFFER_PRICE = 'lowestOfferPrice',
}
export enum MY_OFFERS_REFS {
  ID = 'id',
  WINENAME = 'wineName',
  LWIN18 = 'lwin18',
  VINTAGE = 'vintage',
  UNITSIZE = 'unitSize',
  OFFER_PRICE = 'price',
  OFFER_QUANTITY = 'initialQuantity',
  QUANTITY_TO_SELL = 'quantityOutstanding',
  HIGHEST_BID = 'highestBid',
  STOP_LIMIT = 'stopLimitPrice',
  WAC = 'wac',
  PHYSICAL_QUANTITY = 'physicalQuantity',
  LAST_UPDATED = 'updatedDateTime',
  CREATED_DATE_TIME = 'createdDateTime',
  ACTIONS = 'actions',
}

export enum SUB_TABS_ENUMS {
  MY_OFFERS = 'my_offers',
  MARKET = 'market',
  SELLS = 'sells',
  BUYS = 'buys',
}
export enum DESCRIPTIONS {
  PORTAL_BUY = 'description',
  PORTAL_SELL = 'portal_sell',
}

export enum TRANSACTION_TYPE_ENUM {
  SELL = 'sell',
  BUY = 'buy',
}

export interface StocksType {
  id: string;
  assetAccountId: string;
  accountType: string;
  lwin18: string;
  lwin11: string;
  vintage: number;
  quantity: number;
  wineName: string;
  unitSize: string;
  highestBid: NumberOrNull;
  marketValue: number;
  totalMarketValue: number;
  wac: StringOrNull;

  physicalQuantity: number;
  region: string;
  lastTradePrice: NumberOrNull;
  tradeDateTime: StringOrNull;
  cultWinesAllocationRegion: StringOrNull;
}

type StringOrNull = string | null;
type NumberOrNull = number | null;
interface BaseType {
  buyOrSell: string;
  price: number;
  initialQuantity: number;
  quantityOutstanding: number;
  stopLimitPrice: number;
  orderType: string;
  wineName: string;
  vintage: number;
  unitSize: string;
  assetId: number;
  lwin18: string;
  userId: string;
  assetAccountId: string;
  createdDateTime: string;
  updatedDateTime: StringOrNull;
  marketValue: number;
  inPackage: boolean;
  highestBid: {
    orderCreatedDatetime: StringOrNull;
    price: NumberOrNull | undefined;
    quantityOutstanding: NumberOrNull;
    orderType: string;
  } | null;
  lowestOffer: {
    orderCreatedDatetime: string;
    price: number;
    orderType: string;
  } | null;
  campaignName: StringOrNull;
  campaignId: StringOrNull;
  packageName: StringOrNull;
  packageId: StringOrNull;
  executionPrice: number;
  wac: StringOrNull;
  physicalQuantity: number;
  region: string;

  lastTradePrice: NumberOrNull;
  tradeDateTime: StringOrNull;
  cultWinesAllocationRegion: StringOrNull;
  isLiquidating: boolean;
}

export interface OffersType extends BaseType {
  id: string;
  priceGbp: number;
  priceInTargetCurrency: number;

  marketValueGbp: number;
  marketValueInTargetCurrency: number;

  stopLimitPriceGbp: number;
  stopLimitPriceInTargetCurrency: number;
}

export interface PortfolioOffersType {
  id: string;
  stopLimitPrice: number;
  lwin18: string;
  price: number;
  unitSize: string;
  vintage: number;
  wineName: string;
  initialQuantity: number;
  quantityOutstanding: number;
  isPaused: number;
  portfolioName: string;
  orderCreatedDateTime: string;
  relationshipManager: string;
  lastUpdated: string;
  wac: null | string;
  physicalQuantity: null | number;
  marketValue: null | number;
  assetAccountId: string;

  highestBid: ActiveMarketOrder;
  lowestOffer: ActiveMarketOrder;
  region: string | null;
  cultWinesAllocationRegion: string | null;
  lastTradedPrice: number | null;
  tradeDateTime: Date | null;

  priceGbp: number;
  priceInTargetCurrency: number;

  marketValueGbp: number;
  marketValueInTargetCurrency: number;

  stopLimitPriceGbp: number;
  stopLimitPriceInTargetCurrency: number;
}

export interface IRowData extends OffersType {
  actions: TABLE_ACTIONS[];
  highestBidPrice?: number;
  lowestOfferPrice?: number;
  WAscore?: number | null;
  vinousScore?: number | null;
  priceRange?: string | null;
}

export interface TradeHistoryType {
  dateTime: string;
  wineName: string;
  region: string;
  producer: string;
  vintage: number;
  unitSize: string;
  lwin18: string;
  quantity: number;
  transactionType: string;
  price: number;
  total: number;
  buyer: string;
  seller: string;
  executionPrice: number;
  orderType: string;
}

export interface TabsPops {
  id: number;
  tabName: string;
}

// export enum DisplayTextKeys {
//   TOTAL_MGMT_FEES = 'total_mgmt_fees',
//   TOTAL_OUTSTANDING_FEES = 'total_outstanding_fees',
//   TOTAL_PAID_FEES = 'total_paid_fees',
//   FILTER_ALL_TEXT = 'filter_all_text',
//   FILTER_OUTSTANDING_TEXT = 'filter_outstanding_text',
//   FILTER_PAID_TEXT = 'filter_paid_text',
//   EXPORT_FILE_NAME = 'export_filename',
//   SIDEOUT_TITLE = 'sildeout_title',
//   TITLE = 'my title',
//   BIDS_AND_OFFERS = 'bidsAndOffers',
// }

export enum TABLE_ACTIONS {
  CUSTOM = 'custom',
  ACCEPT_HIGHEST_BID = 'AcceptBidMarket',
  PURCHASE = 'purchase',
  CREATE_OFFER = 'createOffer',
  EDIT_OFFER = 'editOffer',
  MY_OFFERS_ACCEPT_HIGHEST_BID = 'AcceptBidOffer',
  CANCEL_OFFER = 'cancelOffer',
  FILTER = 'filter',
}

export enum QUERY_KEYS {
  // FETCH_OFFERS_IN_BG = 'fetchOffersInBackground',
  // FETCH_PORTFOLIO_OFFERS_IN_BG = 'fetchPortfolioOffersInBackground',
  // FETCH_PORTFOLIO_STOCK_IN_BG = 'fetchPortfolioStocksInBackground',

  FETCH_BID = 'fetchBid',
  FETCH_OFFERS = 'fetchOffers',
  FETCH_PORTFOLIO_OFFERS = 'fetchPortfolioOffers',
  FETCH_PORTFOLIO_OFFERS_DETAILS = 'fetchPortfolioOffersDetails',
  FETCH_PORTFOLIO_STOCK = 'fetchPortfolioStocks',
  FETCH_TRANSACTION_REPORT = 'fetchTransactionReport',
  FETCH_OFFERS_SEARCH = 'fetchOffersSearch',
  FETCH_PORTFOLIO_OFFERS_SEARCH = 'fetchPortfolioOffersSearch',
}

export interface IGenerateTableActions {
  row: OffersType | PortfolioOffersType;
  tableType: SUB_TABS_ENUMS;
  portfolioStocks?: StocksType[];
}

export interface IGenerateRowsFormat {
  fxRate: number;
  currentSubTab: string;
  offers: OffersType[];
  portfolioStocks: StocksType[];
  portfolioOffers: PortfolioOffersType[];
  activeOfferPriceListMap: ActiveOfferPriceListFormatedType[];
}

export enum TabTypes {
  BIDS_AND_OFFERS = 'bidsAndOffers',
  TRADE_HISTORY = 'tradeHistory',
}

export interface SlideoutConfigType {
  showBackButton?: boolean;
  isOpen?: boolean;
  view?: ViewStateType;
  innerViewState?: ViewStateType;
  title?: string;
  onBack?: () => void;
  isDetailsFetched?: boolean;
  data?: Record<string, unknown>;
  slidoutView?: TABLE_ACTIONS;
  offer?: OffersType;
  portfolioOffer?: PortfolioOffersType;
  templateConfig?: MessageType;
  onCTA?: (viewState?: ViewStateType) => void;
  refetch?: () => void;
  source?: OffersType[];
  filters?: Record<string, { label: string; selected: string[] }>;
  getFilteredSource?: (isFiltered: boolean, data: Record<string, unknown>[]) => void;
  wineInfoTemplateConfig?: IWineInfoTemplate['config'];
  marketOffers?: OffersType[];
  extraConfig?: {
    initSelectedWine?: StocksType | null;
    initOnBack?: () => void;
    success?: {
      onSuccess?: () => void;
      cta?: () => void;
    };
    error?: {
      onError?: () => void;
      cta?: () => void;
    };
  };
}

export type SetSlideoutConfigType = Dispatch<SetStateAction<SlideoutConfigType>>;

export enum onTradeTopUpEnum {
  onTradeToupFailure = 'onTradeToupFailure',
  onTradeToupSuccess = 'onTradeToupSuccess',
}

export interface TradePurchaseRefType extends IPurchaseParams {
  prevAcctBalance?: number;
  name?: string;
  priceGbp?: number;
  stringRef?: string;
  priceInTargetCurrency?: number;
  subtitle?: string;
}

export enum OfferTypeEnum {
  CUSTOM = 'custom',
  MARKET_OFFERS = 'marketOffers',
  PORTFOLIO_OFFERS = 'portfolioOffers',
}

export interface SeletedWineType extends StocksType {
  marketValueGbp: number;
  marketValueInTargetCurrency: number;
}

export enum NoResultsTextKeys {
  TITLE = 'title',
  SUBTITLE = 'subTitle',
}

export enum DisplayTextKeys {
  RESULT = 'result',
  RESULTS = 'results',
  LOADING = 'loading',
  LIVE = 'live',
  CREATE_OFFER = 'createOffer',
  ACCEPT_BID = 'acceptBid',
  TOTAL_VALUE = 'total_value',
  UINTS_OWNED = 'uints_owned',
  VALUE_PER_UNIT = 'value_per_unit',
  SEARCH = 'search',
  NOT_FOUND = 'notFound',
  NO_STOCK_FOUND = 'noStockFound',
  NEXT = 'next',
  SUBMIT_OFFER = 'submitOffer',
  PLEASE_SELECT_QUANTITY = 'please_select_quantity',
  TOTAL_MARKET_VALUE = 'total_market_value',
  DEFAULT_STOP_LIMIT = 'default_stop_limit',
  ENTER_NEW_STOP_LIMIT = 'enter_new_stop_limit',
  STOP_LIMIT = 'stop_limit',
  UPDATE = 'update',
  CANCEL = 'cancel',
  NUMBER_OF_UNIT_AVAILABLE = 'number_of_unit_available',
  LOWEST_OFFER = 'lowestOffer',

  CANCEL_OFFER_MESSAGE = 'cancelOfferMessage',
  ACCEPT_BID_MESSAGE = 'acceptBidMessage',
  CONTACT_US_INFO = 'contactUs_info',
  SEARCH_STOCK_PLACEHOLDER = 'search_stock_placeHolder',
  STOP_LIMIT_MESSAGE = 'stop_limit_message',
  SELECT_QUANTITY_LABEL = 'selectQuantityLabel',
  MAKE_OFFER_EXPLAINER = 'makeOfferExplainer',
  CLEAR_FILTERS = 'clearFilters',
  FILTERS = 'filters',
  APPLY_BUTTON_TEXT = 'applyButtonText',
}
