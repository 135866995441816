import { Button } from 'components';
import Loading from 'components/Loading/loading';
import { TableColumnType } from 'components/Table';
import { AppContext } from 'context/ContextProvider';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buildTableRow } from 'views/Portfolio/helpers';
import {
  DisableSortBid,
  InitialiseMarketTable,
  InitialiseMyOffersTable,
  LoadingComponent,
  SortBidsNotification,
  generateRowsFormat,
  subTabsBidsAndOffers,
} from 'views/Trade/helpers';
import { useSilentFetch } from 'views/Trade/hooks/useSilentFetch';
import { fetchOffers } from 'views/Trade/services/fetchOffers';
import { fetchPortfolioOffers } from 'views/Trade/services/fetchPortfolioOffers';
import { fetchPortfolioStocks } from 'views/Trade/services/fetchPortfolioStocks';
import {
  DisplayTextKeys,
  IRowData,
  MARKET_REFS,
  NoResultsTextKeys,
  OffersType,
  PortfolioOffersType,
  QUERY_KEYS,
  SUB_TABS_ENUMS,
  SetSlideoutConfigType,
  SlideoutConfigType,
  TABLE_ACTIONS,
} from 'views/Trade/types';
import MessageTemplate from 'components/ProductTemplates/components/MessageTemplate';
import { buildDisplayText, capitalizeFirstLetter, sortItems } from 'utils';
import ExpandingSearch from 'components/ExpandingSearch';
import { useFetch } from 'views/Trade/hooks/useFetch';
import { useSnackbar } from 'notistack';
import SlideOutPanel from 'components/SlideOutPanel';
import { FilterIcon } from 'assets/icons';
import ColumnSortTable from 'components/Table/ColumnSortTable';
import { ActiveOfferPriceListFormatedType, ActiveOfferPriceListType, PortalDocumentTypeEnum } from 'types/serviceTypes';
import useFetchPortalDocument from 'hooks/useFetchPortalDocument';
import { formatToObject } from 'views/shared/helpers';
import noResultImage from '../../../../assets/images/no_results_image.png';
import FilterView, { FilterViewRef } from '../SlideOutView/FilterView';

interface BidsAndOffersParams {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}

const BidsAndOffers = ({ slideOutConfig, setSlideOutConfig }: BidsAndOffersParams) => {
  const {
    state: {
      settings: { accountInfo },
    },
    gbpToTargetCurrency,
  } = useContext(AppContext);

  const { t } = useTranslation();

  const filterViewRef = useRef<FilterViewRef>(null);

  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const [searchText, setSearchText] = useState<string>('');
  const [currentSubTab, setCurrentSubTab] = useState<string>(SUB_TABS_ENUMS.MARKET);
  const [filterSlideOutConfig, setFilterSlideOutConfig] = useState({
    onBack: () => {},
    isOpen: false,
    isFiltered: false,
    filterData: [] as IRowData[],
  });
  const [tableSortConfig, setTableSortConfig] = useState({
    sortFunction: null,
    numberOfPrevSort: 0,
    numberOfSort: 0,
    sortKey: '',
    isAsc: true,
  });

  const { enqueueSnackbar } = useSnackbar();
  const PAGE_SIZE = 200;

  const fetchStocksSetup = useMemo(() => {
    return {
      fetchPortfolioStocksSetup: {
        pageSize: PAGE_SIZE,
        queryFn: fetchPortfolioStocks,
        params: {},
        queryKey: QUERY_KEYS.FETCH_PORTFOLIO_STOCK,
        timeOutBetweenFetch: 4000,
      },

      fetchPortfolioOffersSetup: {
        pageSize: PAGE_SIZE,
        queryFn: fetchPortfolioOffers,
        params: {},
        queryKey: QUERY_KEYS.FETCH_PORTFOLIO_OFFERS,
        timeOutBetweenFetch: 3000,
      },

      fetchOffersSetup: {
        pageSize: PAGE_SIZE,
        queryFn: fetchOffers,
        params: {},
        queryKey: QUERY_KEYS.FETCH_OFFERS,
        timeOutBetweenFetch: 2000,
      },
    };
  }, []);

  const {
    isBackgroundFetchActive: isAllPortfolioStocks,
    response: portfolioStocks,
    isActive: isActivePortfolioStocks,
    initIsLoading: isLoadingPortfolioStocks,
  } = useSilentFetch(fetchStocksSetup.fetchPortfolioStocksSetup);

  const { response: portfolioOffers, initIsLoading: isLoadingPortfolioOffers } = useSilentFetch(
    fetchStocksSetup.fetchPortfolioOffersSetup,
  );
  const {
    isBackgroundFetchActive: isAllMarketOffers,
    isActive: isActiveMarketOffers,
    response: marketOffers,
    initIsLoading: isLoadingOffers,
  } = useSilentFetch(fetchStocksSetup.fetchOffersSetup);

  const { isLoading: loadingMarketSearch, responce: marketSearchData } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    isEnabled: searchText.length > 0 && currentSubTab === SUB_TABS_ENUMS.MARKET,
    queryFn: () => fetchOffers({ searchText: searchText, page: 0, pageSize: PAGE_SIZE }),
    queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, searchText],
  });

  const { isLoading: loadingPortfolioOfferSearch, responce: portfolioOfferSearchData } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    isEnabled: searchText.length > 0 && currentSubTab === SUB_TABS_ENUMS.MY_OFFERS,
    queryFn: () => fetchPortfolioOffers({ search: searchText, page: 0, pageSize: PAGE_SIZE }),
    queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS_SEARCH, searchText],
  });

  const { data: activeOfferPriceListData, isLoading: isLoadingActiveOfferPriceList } = useFetchPortalDocument({
    docType: PortalDocumentTypeEnum.ACTIVE_OFFER_PRICE_LIST,
  });

  const activeOfferPriceList = useMemo(() => {
    if (!activeOfferPriceListData?.data || isLoadingActiveOfferPriceList) return [];
    const data = activeOfferPriceListData?.data as ActiveOfferPriceListType[];
    return formatToObject(data[0].wines.columns, data[0].wines.rows) as unknown as ActiveOfferPriceListFormatedType[];
  }, [activeOfferPriceListData?.data, isLoadingActiveOfferPriceList]);

  const searchMarketOffers = useMemo(() => {
    if (!marketSearchData?.data || loadingMarketSearch) return [];
    return marketSearchData?.data || [];
  }, [marketSearchData?.data, loadingMarketSearch]);

  const searchPortfolioOffer = useMemo(() => {
    if (!portfolioOfferSearchData?.data || loadingPortfolioOfferSearch) return [];
    return portfolioOfferSearchData?.data || [];
  }, [portfolioOfferSearchData?.data, loadingPortfolioOfferSearch]);

  const { myOffersColumns, marketColumns, MARKET_TABLE_TEXTS } = useMemo(() => {
    return {
      ...InitialiseMyOffersTable(t, slideOutConfig, setSlideOutConfig, gbpToTargetCurrency),
      ...InitialiseMarketTable(t, slideOutConfig, setSlideOutConfig, gbpToTargetCurrency),
    };
  }, [slideOutConfig, setSlideOutConfig, t, gbpToTargetCurrency]);

  useEffect(() => {
    if (currentSubTab) filterViewRef.current?.resetFilters?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubTab]);

  const tableConfig = useMemo(() => {
    const fxRate = accountInfo?.account.fxRate || 1;
    const isSearch = searchText.length > 0;

    let storeOffers: OffersType[] = [];
    let storePortfolioOffers: PortfolioOffersType[] = [];

    if (isSearch && currentSubTab === SUB_TABS_ENUMS.MARKET) {
      storeOffers = searchMarketOffers;
    } else {
      storeOffers = marketOffers;
    }

    if (isSearch && currentSubTab === SUB_TABS_ENUMS.MY_OFFERS) {
      storePortfolioOffers = searchPortfolioOffer;
    } else {
      storePortfolioOffers = portfolioOffers;
    }

    const { dataSource: mainDataSource, numberOfBidAgainstStock } = generateRowsFormat({
      fxRate,
      currentSubTab,
      offers: storeOffers,
      portfolioOffers: storePortfolioOffers,
      portfolioStocks: portfolioStocks,
      activeOfferPriceListMap: activeOfferPriceList,
    });

    let source = filterSlideOutConfig.isFiltered ? filterSlideOutConfig.filterData : mainDataSource;

    let columns: TableColumnType[] = [];
    let isLoading = true;

    if (currentSubTab === SUB_TABS_ENUMS.MARKET) {
      columns = marketColumns;
      isLoading =
        (!isSearch && isLoadingOffers) || (!isSearch && isLoadingPortfolioStocks) || (loadingMarketSearch && isSearch);
    } else {
      columns = myOffersColumns;
      isLoading = (!isSearch && isLoadingPortfolioOffers) || (loadingPortfolioOfferSearch && isSearch);
    }

    if (tableSortConfig.numberOfSort !== tableSortConfig.numberOfPrevSort && !tableSortConfig.sortKey) {
      source.sort((a, b) => {
        if ((a.actions?.length ?? 0) < (b.actions?.length ?? 0)) return 1;
        if ((a.actions?.length ?? 0) > (b.actions?.length ?? 0)) return -1;
        return (b.highestBid?.price || 0) - (a.highestBid?.price || 0);
      });
      setTableSortConfig((prev) => ({ ...prev, numberOfPrevSort: tableSortConfig.numberOfSort }));
    }

    if (tableSortConfig.numberOfSort !== tableSortConfig.numberOfPrevSort && tableSortConfig.sortKey) {
      source = sortItems(
        source as unknown as Record<string, string | number>[],
        tableSortConfig.isAsc,
        tableSortConfig.sortKey,
      ) as unknown as IRowData[];
      setTableSortConfig((prev) => ({ ...prev, numberOfPrevSort: tableSortConfig.numberOfSort }));
    }

    const rows = (source || []).map((data) => {
      return {
        ...buildTableRow(
          {
            ...data,
          },
          columns,
        ),
      };
    });

    return {
      rows,
      source,
      mainDataSource,
      columns,
      isLoading,
      numberOfBidAgainstStock: numberOfBidAgainstStock ?? 0,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeOfferPriceList,
    portfolioStocks,
    isLoadingPortfolioStocks,
    portfolioOffers,
    isLoadingPortfolioOffers,
    marketOffers,
    isLoadingOffers,
    currentSubTab,
    accountInfo?.account,
    searchText,
    marketSearchData,
    loadingMarketSearch,
    searchPortfolioOffer,
    loadingPortfolioOfferSearch,
    tableSortConfig.numberOfSort,
    filterSlideOutConfig.filterData.length,
    filterSlideOutConfig.isFiltered,
    filterViewRef.current,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isActivePortfolioStocks && !isActiveMarketOffers && tableConfig.numberOfBidAgainstStock > 0) {
        enqueueSnackbar('', {
          autoHideDuration: 4000,
          content: () => {
            return (
              <SortBidsNotification
                id={1}
                message={`${tableConfig.numberOfBidAgainstStock} ${t('trade:common.bids_found_against')}`}
                sortFunction={() =>
                  setTableSortConfig((prev) => ({ ...prev, numberOfSort: prev.numberOfSort + 1, sortKey: '' }))
                }
              />
            );
          },
        });
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [
    tableConfig.isLoading,
    tableConfig.numberOfBidAgainstStock,
    isActivePortfolioStocks,
    isActiveMarketOffers,
    enqueueSnackbar,
    t,
  ]);

  const onSearch = (search: string) => {
    setSearchText(search);
  };

  return (
    <div className="flex flex-1 sm:p-5 bg-[#F2F2F2] w-full flex-col">
      <div className="flex items-start gap-1 mb-4 justify-between mt-2 md:items-center px-4 py-2 md:px-0 md:py-0">
        <div className="flex gap-1 flex-1 flex-col items-start justify-center  md:flex-row md:items-center md:justify-normal ">
          <div className="flex flex-row items-center gap-1 ">
            <div className="flex gap-2 items-center">
              <div className="flex  items-center text-sm border rounded-full overflow-hidden ">
                {subTabsBidsAndOffers.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => setCurrentSubTab(tab.tabName)}
                    className={`py-1 px-4 border-r whitespace-nowrap ${
                      currentSubTab === tab.tabName ? 'bg-vine text-white' : null
                    }`}
                  >
                    {t(`trade:common.${tab.tabName}`)}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-1  items-center ">
            <FilterView
              ref={filterViewRef}
              isOpen={filterSlideOutConfig?.isOpen as boolean}
              customFilters={{
                [MARKET_REFS.REGION]: {
                  label: t('trade:dropDownFilters.title.region'),
                  selectAllText: t('trade:dropDownFilters.value.region'),
                  selected: [],
                  previewSelected: [],
                },

                [MARKET_REFS.PRODUCER]: {
                  label: t('trade:dropDownFilters.title.producer'),
                  selectAllText: t('trade:dropDownFilters.value.producer'),
                  selected: [],
                  previewSelected: [],
                },

                [MARKET_REFS.VINTAGE]: {
                  label: t('trade:dropDownFilters.title.vintage'),
                  selectAllText: t('trade:dropDownFilters.value.vintage'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                },

                [MARKET_REFS.UNITSIZE]: {
                  label: t('trade:dropDownFilters.title.size'),
                  selectAllText: t('trade:dropDownFilters.value.size'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                },
                [MARKET_REFS.WA_SCORE]: {
                  label: t('trade:dropDownFilters.title.WA'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                },

                [MARKET_REFS.VINOUS_SCORE]: {
                  label: t('trade:dropDownFilters.title.vinous'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                },

                [MARKET_REFS.PRICE_RANGE]: {
                  label: t('trade:dropDownFilters.title.price'),
                  selectAllText: t('trade:dropDownFilters.value.price'),
                  selected: [],
                  previewSelected: [],
                  isAsc: true,
                  customSortOrder: ['Under £500', 'Under £1000', '£1k-£2k', '£2k-£5k', '£5k-£10k', '£10k+'],
                  customFilterFunction: (customFilters, sourceData) => {
                    const filter = customFilters[MARKET_REFS.PRICE_RANGE];
                    let source = sourceData as IRowData[];

                    const preview = filter.previewSelected;
                    if (preview.length > 0) {
                      source = source.filter((x) => {
                        if (preview.includes('Under £1000')) {
                          return (
                            preview.includes(String(x[MARKET_REFS.PRICE_RANGE])) ||
                            String(x[MARKET_REFS.PRICE_RANGE]) === 'Under £500'
                          );
                        }
                        return filter.previewSelected.includes(String(x[MARKET_REFS.PRICE_RANGE]));
                      });
                    }
                    return source;
                  },
                },
              }}
              sourceData={tableConfig.source as IRowData[]}
              setFilteredSource={(isFiltered, filterData) => {
                setFilterSlideOutConfig((prev) => ({
                  ...prev,
                  isFiltered: isFiltered,
                  filterData: filterData as unknown as IRowData[],
                  isOpen: false,
                }));
              }}
            />
          </div>
        </div>

        <div className="flex gap-2  flex-col items-end md:flex-row md:items-center">
          <Button
            isDisable={isLoadingPortfolioStocks}
            onClick={() =>
              setSlideOutConfig({
                isOpen: true,
                slidoutView: TABLE_ACTIONS.CREATE_OFFER,
                title: 'Create Offers',
                wineInfoTemplateConfig: { showTemplate: false },
              })
            }
            className="text-sm bg-vine max-h-8  text-white rounded-md disabled:bg-gray-700 whitespace-nowrap"
          >
            {displayText[DisplayTextKeys.CREATE_OFFER]}
          </Button>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center px-4  mb-2 md:px-0">
        <LoadingComponent
          className=""
          currentSubTab={currentSubTab}
          isLoading={
            (!searchText && isAllMarketOffers) ||
            (!searchText && isAllPortfolioStocks) ||
            (loadingMarketSearch && searchText) ||
            tableConfig.isLoading
          }
          loadingText={displayText[DisplayTextKeys.LOADING]}
          resultLength={tableConfig.source.length}
          resultText={capitalizeFirstLetter(displayText[DisplayTextKeys.RESULTS])}
          liveText={displayText[DisplayTextKeys.LIVE]}
        />

        <div className="mr-[-4px]">
          <ExpandingSearch
            uniqueIdRef=""
            placeholder={displayText[DisplayTextKeys.SEARCH]}
            data={[]}
            onSearch={(_, search) => onSearch(search || '')}
            className="focus:bg-white !max-h-8 "
            props={[]}
          />
        </div>
      </div>

      <div className="justify-center items-center bg-white rounded-b-md flex-1 h-full ">
        {tableConfig.isLoading && <Loading />}

        <div
          className={`overflow-hidden ${!tableConfig.isLoading && tableConfig.rows.length > 0 ? 'visible' : 'hidden'}`}
        >
          <ColumnSortTable
            disableSort={searchText.length > 0 ? loadingMarketSearch : isActivePortfolioStocks || isActiveMarketOffers}
            disableSortFunc={() =>
              enqueueSnackbar('', { content: <DisableSortBid id={2} message={t('trade:common.sort_is_disabled')} /> })
            }
            // defaultSortKey={MARKET_REFS.WINENAME}
            dataSource={tableConfig.mainDataSource as IRowData[]}
            updateDataSource={(sortResult, sortKey, isAsc) => {
              setTableSortConfig((prev) => ({
                ...prev,
                isAsc: Boolean(isAsc),
                sortKey: String(sortKey),
                numberOfSort: prev.numberOfSort + 1,
              }));
            }}
            columns={tableConfig.columns}
            rows={tableConfig.rows}
            applyfadeOnScrollClassName={false}
            isFadeOnScrollDisable={true}
          />
        </div>

        {!tableConfig.isLoading && !tableConfig.rows.length && (
          <div className="animate-[fade-in_5s_ease-out]">
            <MessageTemplate
              showButton={false}
              displayTextKeys={NoResultsTextKeys}
              translationKey="common:noResult"
              imageSrc={noResultImage}
              title={
                <div className="flex items-center gap-3">
                  <span className="text-20 font-medium ">{t('common:noResult.title')}</span>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BidsAndOffers;
